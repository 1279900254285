<template>
  <div class="main-container">
    <div>
      <h2 class="main_content_heading">Why Us ?</h2>
      <img src="../../img/profile.jpg" class="main_content_img_common" />

      <div class="content_base_template">
        <p>
          With the abundance of Forex brokers in the market, traders are spoilt
          for choice to decide for best cost of trade and best returns on their
          investment. This is where TrustFxCapital strives to stand out by going to
          the distance to offer their clients direct access to inter-bank
          liquidity, tight spreads, fast and quality execution and exceptional
          customer service.
          <br />
          <br />
          Our services for professionals and active traders, new traders and
          money managers are based on the following main principles.
        </p>
      </div>

      <div class="content_base_template">
        <p>
          TrustFxCapital acknowledges the importance of compliance with all relevant
          laws, rules, regulations, policies and standards. TrustFxCapital is
          committed to having strict management discipline and first-class
          controls in our compliance environment. As a regulated entity
          TrustFxCapital is required to meet strict financial standards, including
          capital adequacy and audit requirements.
          <br />
          <br />
          TrustFxCapital holds clients money in client segregated accounts in top
          rated banks and these funds cannot be used for any purpose other than
          to maintain margin needed to cover positions opened by clients.
        </p>
      </div>

      <div class="content_base_template">
        <h2>Lowest spreads</h2>
        <p>
          Our trading model provides clients with transparent execution and very
          competitive multi-bank bid/offer spreads where you can get spreads as
          low as 0.5. We are constantly investing in technology and building
          strong relationship with most reliable liquidity providers for
          providing our client best possible trading conditions.
        </p>
      </div>

      <div class="content_base_template">
        <h2>Fast execution, No Re-quotes</h2>
        <p>
          Orders are executed instantly without being passed through any third
          party or dealing desk. This execution type guarantees all trades will
          never experience re-quotes or any kind of manipulation. Dedicated
          connection to our liquidity providers ensures the lowest latency and
          fastest possible trade execution for our clients.
          <br />
          <br />
          Our trading model eliminates conflicts of interest inherent in some
          other dealing desk platforms. We do not take positions against our
          clients, we fill client’s orders from the best bid/offer prices
          available to us from our liquidity providers.
        </p>
      </div>

      <div class="content_base_template">
        <h2>No Trading Restrictions</h2>
        <p>
          The TrustFxCapital trading environment has been created with traders in
          mind as no forex trading strategies are restricted. Our leading
          trading conditions allow our clients to maximize markets by allowing
          Hedging, Scalping and use of automated trading EA’s.
        </p>
      </div>

      <div class="content_base_template">
        <h2>Swap Free Accounts</h2>
        <p>
          TrustFxCapital offers its clients interest free accounts according to
          their religious beliefs or for clients who use trading systems that do
          not take into account the impact of Swaps. These Swap Free/Roll Over
          fee free accounts are termed as ‘Islamic Accounts’ and when trading on
          the swap-free account with any currency pair, a trader does not gain
          or lose any amount regardless of the position volume. Even keeping the
          trade open for a long time, a trader can be sure that only the
          exchange rate will affect the trade outcome.
        </p>
      </div>

      <div class="content_base_template">
        <h2>Minimum Deposit</h2>
        <p>
          At TrustFxCapital we understand that not all new traders ready to take
          huge risk with their investment, and hence we offer our clients to
          start trading with as little as $10 by utilizing our Micro account. In
          fact, this facility is even used by many of our prospective clients to
          check our trading conditions before investing bigger sum.
        </p>
      </div>

      <div class="content_base_template">
        <h2>Free personalized Training</h2>
        <p>
          At TrustFxCapital we understand that every trader is unique and as per
          this philosophy we offer various kinds of training solutions as per
          clients needs. We provide special one-to-one training, seminars and
          webinars for large number of clients so as to make our clients better
          understands the markets fully and to maximize their profits on their
          investment.
        </p>
      </div>

      <div class="content_base_template">
        <h2>Confidentiality</h2>
        <p>
          TrustFxCapital maintains appropriate controls and monitors the transfer of
          confidential and sensitive client information within and outside the
          Company on a need-to-know basis. TrustFxCapital does not deliberately
          disclose client information unless this is upon request of the client,
          or as required to do so by law.
        </p>
      </div>

      <div class="content_base_template">
        <h2>Unrivalled Customer Service</h2>
        <p>
          TrustFxCapital strives to offer you the best possible customer service and
          support. Our multilingual team of professionals has a significant
          amount of experience within the forex industry, so they understand
          what traders want and need. You can trade with confidence knowing that
          the TrustFxCapital team will always be there to help you.
          <br />
          <br />
          Professionalism, experience and practical knowledge in financial and
          currency markets is what makes our employees the best guarantee of
          exceptionally sound professional decisions, which in term can help to
          maximize our clients trading experience with us.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "trust-fx-capital-why-us",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
