<template>
  <div id="app">
    <trust-fx-capital-header />
    <trust-fx-capital-navigation />
    <trust-fx-capital-actions />
    <router-view />
    <trust-fx-capital-footer />
    <trust-fx-capital-side-nav />
  </div>
</template>

<script>
import TrustFxCapitalHeader from './components/TrustFxCapitalHeader.vue';
import TrustFxCapitalActions from './components/TrustFxCapitalActions.vue';
import TrustFxCapitalNavigation from './components/TrustFxCapitalNavigation.vue';
import TrustFxCapitalFooter from './components/TrustFxCapitalFooter.vue';
import TrustFxCapitalSideNav from './components/TrustFxCapitalSideNav.vue';

export default {
  name: 'App',
  components: {
    TrustFxCapitalNavigation,
    TrustFxCapitalFooter,
    TrustFxCapitalSideNav,
    TrustFxCapitalHeader,
    TrustFxCapitalActions
  },
  beforeCreate() {
    let externalScript = document.createElement('script');
    externalScript.setAttribute('src', 'https://smtpjs.com/v3/smtp.js');
    document.head.appendChild(externalScript);
    document.addEventListener('contextmenu', event => event.preventDefault());
    event.preventDefault();
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  background: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

.form__register__input, .form__register select {
    padding: 0.4rem;
    width: 100%;
    border: 1.1px solid #bbb;
    border-radius: 0.2rem;
    color: #777;
}
.main-container {
  margin: 0 15%;
  width: 70% !important;
  color: white;
}

.container {
  width: 70% !important;
}
.content_base_template {
  margin: 1rem 0;
}

.main_content_heading {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
}
.product_image {
    width: 50rem;
}
.table_basic_metal {
  border-collapse: collapse;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.table_basic_metal td,
.table_basic_metal th {
  padding: 0.6rem 1rem;
  font-size: 1rem;
  text-align: left;
}

td:first-child,
th:first-child {
  padding-left: 0;
}

.table_market_hours td:first-child {
  padding-left: 1rem;
}
.main_content_img_common {
  width: 100%;
}
.product_image {
  width: 100%;
}
@media screen and (max-width: 500px) {
  .main-container {
    margin: 0 5% !important;
    width: 90% !important;
  }

  .logo-text {
    font-size: 1rem;
  }
}
</style>
