<template>
  <div class="trust-fx-capital-footer">
    <div class="main-container trust-fx-capital-footer__main-container">
      <!-- <div class="row">
        <div class="col-sm-3 award-container">
          <img src="../img/award1.png" class="award" />
        </div>
        <div class="col-sm-3 award-container">
          <img src="../img/award2.png" class="award" />
        </div>
        <div class="col-sm-3 award-container">
          <img src="../img/award3.png" class="award" />
        </div>
        <div class="col-sm-3 award-container">
          <img src="../img/award4.png" class="award" />
        </div>
      </div> -->
      <div class="row">
        <div class="col-sm-1">
          <router-link to="/trustfxcapital/">
            Explore
          </router-link>
        </div>
        <div class="col-sm-1">
          <router-link to="/trustfxcapital/">
            Home
          </router-link>
        </div>
        <div class="col-sm-1">
          <router-link to="/trustfxcapital/contactUs">
            Contact
          </router-link>
        </div>
      </div>
      <hr />
      <div class="footer-text">
        <p></p>
        <p>
          <b>TrustFxCapital brand</b> is authorized and regulated in various
          jurisdictions.
        </p>
        <p></p>
        <p>
          <b>TrustFxCapital Limited</b> (<router-link to="/trustfxcapital/"
            >www.trustfxcapital.in</router-link
          >) is regulated by the Cyprus Securities and Exchange Commission.
        </p>
        <p>
          <strong>TrustFxCapital</strong>&nbsp;(<router-link
            to="/trustfxcapital/"
            >www.trustfxcapital.in</router-link
          >) is authorised and regulated by the Financial Conduct Authority with
          license number 721837.
        </p>
        <p>
          <b>TrustFxCapital Limited</b> (<router-link to="/trustfxcapital/">www.trustfxcapital.in</router-link>) is
          regulated by the Financial Services Commission of the Republic of
          Mauritius with an Investment Dealer License bearing license number
          C117712234.
        </p>
        <p>
          Card transactions are processed via FT Global Services Ltd, Reg No. HE
          33936 and registered address at 10 JLN Serene,
          Serene Center, Singapore 258748. Address for cardholder correspondence:
          <a href="mailto:customersupport@trustfxcapital.in">customersupport@trustfxcapital.in.</a> Business
          location address: TrustFxCapital Pvt. Ltd.36/37, Oxford Street, Manchester M1 5EJ, United Kingdom.
        </p>
        <p>
          Exinity Limited is a member of Financial Commission, an international organization engaged in a
          <router-link to="/trustfxcapital/contactUs">
            resolution of disputes
          </router-link>
          within the financial services industry in the Forex market.
        </p>
        <p>
          <b>Risk Warning:</b> Trading Forex and Leveraged Financial
          Instruments&nbsp;involves significant risk and can result in the loss
          of your invested capital. You should not invest more than you can
          afford to lose and should ensure that you fully understand the risks
          involved. Trading leveraged products may not be suitable for all
          investors.&nbsp;Trading non-leveraged products such as stocks also
          involves risk as the value of a stock can fall as well as rise, which
          could mean getting back less than you originally put in. Past
          performance is no guarantee of future results.&nbsp;Before trading,
          please take into consideration your level of experience, investment
          objectives and seek independent financial advice if necessary. It is
          the responsibility of the Client to ascertain whether he/she is
          permitted to use the services of the TrustFxCapital brand based on the legal
          requirements in his/her country of residence.&nbsp; Please read TrustFxCapital’s
          full <router-link to="/trustfxcapital/whatIsForex">Risk Disclosure</router-link>.
        </p>
        <p></p>
        <p></p>
        <p>
          <b>Regional restrictions</b>:&nbsp;TrustFxCapital brand does not provide
          services to residents of the USA, Mauritius, Japan, Canada, Haiti,
          Suriname, the Democratic Republic of Korea, Puerto Rico, Brazil, the
          Occupied Area of Cyprus and Hong Kong. Find out more in the
          <router-link to="/trustfxcapital/whatIsForex">Regulations</router-link> section of
          our FAQs.
        </p>
      </div>
      <div class="trust-fx-capital-footer__copyright">
        © Copyright by TrustFxCapital. 2014 | All Rights reserved.
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "trust-fx-capital-footer",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.trust-fx-capital-footer {
  text-align: center;
  padding: 30px;
  background-color: white;
  color: black;
  text-align: left;

  hr {
    border-color: black;
    opacity: 1;
  }

  .award-container {
    margin-bottom: 1rem;
  }
  .award {
    width: 14rem;
  }

  .footer-text {
    font-size: 0.8rem;
    line-height: 1rem;

    a {
      color: #0c9cdb;
      outline: none;
      text-decoration: none;
    }

    a:hover {
      color: #2291cd;
    }
  }
  &__main-container {
    margin-bottom: 10px;
    color: black;

    > div > div > a {
      text-decoration: none;
      color: black;
    }
  }
  &__copyright {
    color: #fff;
    opacity: 0.8;
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
  }
  @media screen and (max-width: 500px) {
    .col-sm-1 {
      width: 30%;
    }

    .col-sm-3 {
      width: 50%;
    }
    .award {
    width: 7rem;
    }
  }
}
</style>
