<template>
  <section class="trust-fx-capital-partners row-custom">
    <div
      id="carouselExampleInterval"
      class="trust-fx-capital-carousel carousel slide"
      data-ride="carousel"
    >
      <div class="carousel-inner">
        <div class="carousel-item active" data-interval="2000">
          <img
            class="payment_gateway_logo"
            src="../../img/payment_gateway_img_1.jpg"
            alt="Payment Gateway 6"
          />
          <img
            class="payment_gateway_logo"
            src="../../img/payment_gateway_img_2.jpg"
            alt="Payment Gateway 7"
          />
          <img
            class="payment_gateway_logo"
            src="../../img/payment_gateway_img_3.jpg"
            alt="Payment Gateway 8"
          />
          <img
            class="payment_gateway_logo"
            src="../../img/payment_gateway_img_4.jpg"
            alt="Payment Gateway 9"
          />
          <img
            class="payment_gateway_logo"
            src="../../img/payment_gateway_img_6.jpg"
            alt="Payment Gateway 10"
          />
        </div>
        <div class="carousel-item" data-interval="2000">
          <img
            class="payment_gateway_logo"
            src="../../img/payment_gateway_img_6.jpg"
            alt="Payment Gateway 6"
          />
          <img
            class="payment_gateway_logo"
            src="../../img/payment_gateway_img_7.jpg"
            alt="Payment Gateway 7"
          />
          <img
            class="payment_gateway_logo"
            src="../../img/payment_gateway_img_3.jpg"
            alt="Payment Gateway 8"
          />
          <img
            class="payment_gateway_logo"
            src="../../img/payment_gateway_img_4.jpg"
            alt="Payment Gateway 9"
          />
          <img
            class="payment_gateway_logo"
            src="../../img/payment_gateway_img_6.jpg"
            alt="Payment Gateway 10"
          />
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-target="#carouselExampleInterval"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-target="#carouselExampleInterval"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </section>
</template>
<script>
export default {
  name: "trust-fx-capital-partners",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.trust-fx-capital-partners {
    text-align: center;
    background-color: #effffc;
}
.payment_gateway_logo {
  height: 4rem;
  margin: 2rem;
  text-align: center;
}
  @media screen and (max-width: 500px) {
    .payment_gateway_logo {
      height: 2rem;
      margin: 1rem;
    }
  }
</style>
