<template>
  <div
    id="carouselExampleInterval"
    class="trust-fx-capital-carousel carousel slide"
    data-ride="carousel"
  >
    <div class="carousel-inner">
      <div class="carousel-item active" data-interval="2000">
        <img
          src="../../img/carousel1.jpeg"
          class="d-block w-100 carousel-image"
        />
        <div class="carousel-caption d-md-block caption-container">
          <h1>
            Stop Dreaming <br />
            Start Trading!
          </h1>
          <p>
            The Simplest and the most powerful trading platform
          </p>
          <button type="button" class="btn btn-danger">
            <router-link to="/trustfxcapital/liveAccount">
              Open Live Account
            </router-link>
          </button>
        </div>
      </div>
      <div class="carousel-item" data-interval="2000">
        <img
          src="../../img/carousel2.jpeg"
          class="d-block w-100 carousel-image"
        />
        <div class="carousel-caption d-md-block caption-container">
          <h1>
            Trade Forex, Stock Indices and Other CFDs <br />
            With First Class Broker
          </h1>
          <p>
            Enjoy a seamless experience with a superior trading conditions
          </p>
          <button type="button" class="btn btn-danger">
            <router-link to="/trustfxcapital/liveAccount">
              Open Live Account
            </router-link>
          </button>
        </div>
      </div>
      <div class="carousel-item" data-interval="2000">
        <img
          src="../../img/carousel3.jpg"
          class="d-block w-100 carousel-image"
        />
        <div class="carousel-caption d-md-block caption-container">
          <h1>
            Now earn upto $100
          </h1>
          <p>
            Refer a friend or family member to
          </p>
          <button type="button" class="btn btn-danger">
            <router-link to="/trustfxcapital/liveAccount">
              Open Live Account
            </router-link>
          </button>
        </div>
      </div>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-target="#carouselExampleInterval"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-target="#carouselExampleInterval"
      data-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "trust-fx-capital-carousel",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.trust-fx-capital-carousel {
  ::v-deep .carousel-inner {
    height: 30rem;
  }
}

.carousel-image {
  filter: blur(4px);
  -webkit-filter: blur(4px);
}

.caption-container {
  font-weight: 1000;
  top: 5rem;
  > h1 {
    font-weight: 1000;
  }
  > button > a {
      text-decoration: none;
      color: white;
  }
}

@media screen and (max-width: 500px) {
  .trust-fx-capital-carousel {
    ::v-deep .carousel-inner {
      height: 15rem !important;
    }
  }
  .caption-container {
  top: 0;

    > h1 {
        font-size: 20px;
    }
  }
}
</style>
