<template>
  <div class="main-container">
    <div>
      <h2 class="main_content_heading">What is Technical Analysis</h2>

      <div class="content_base_template">
        <p>
          Technical analysis is a technique used to forecast the future
          direction of prices through the study of historical market data,
          primarily price, volume and open interest.

          <br />
          <br />
          Technical traders use trading information (such as previous prices and
          trading volume) along with mathematical indicators to make their
          trading decisions. This information is usually displayed on a
          graphical chart updated in real time that is interpreted in order to
          determine when to buy and when to sell a specific instrument.
        </p>
      </div>
      <hr />

      <div class="content_base_template">
        <h3>
          The basis of modern technical analysis is made up of the following:
        </h3>
        <br />
        <ul>
          <li>
            The price is a comprehensive reflection of all market forces. At any
            given time, all market information and forces are reflected in the
            prices.
          </li>
          <li>
            Prices move in trends that can be identified and turned into profit
            opportunities.
          </li>
          <li>
            Price movements are historically repetitive.
          </li>
        </ul>
        <br />
        <br />
        <p>
          Almost every trader uses some form of technical analysis. Even
          fundamental analysis traders are likely to glance at price charts
          before executing a trade, as these charts help traders determine ideal
          entry and exit points for a trade. They provide a visual
          representation of the historical price action of whatever is being
          studied. As it is focused on identifying trend reversal, the question
          of timing to enter a trade is easier to address with technical
          analysis.
        </p>
      </div>
      <hr />

      <div class="content_base_template">
        <h3>Chart Analysis</h3>
        <p>
          Forex chart analysis is a main tool of technical analysis. Charts
          generally depict all the data obtained on the currency market and it
          does not matter, what chart you are looking at, it conveys very
          important and detailed information. Thus, your success on the Forex
          market directly depends on your chart analysis skills. Traders usually
          work with those charts that are more convenient and understandable for
          them and that meet their personal preferences and requirements. Chart
          time frame could be expressed in minutes, hours, days, or weeks.
        </p>
        <br />
        <p>
          The main types of charts include line charts, bar charts, and
          Candlesticks.
        </p>
      </div>

      <hr />

      <div class="content_base_template">
        <h3>Bar chart</h3>
        <p>
          Each bar represents one period of time and that period can be anything
          from one minute to one month to several years. A simple bar chart
          shows opening and closing prices as well as highs and lows. The bottom
          of the vertical bar shows the lowest trade price for that time, while
          the top of the bar is the highest price that was paid.
        </p>
      </div>

      <hr />

      <div class="content_base_template">
        <h3>Candlesticks</h3>
        <p>
          Candlestick patterns can be used to forecast the market. Because of
          their colored bodies, candlesticks provide greater visual detail in
          their chart patterns than bar charts so they are easier to follow.
          <br />
          <br />
          A candlestick chart indicates high to low with vertical line. The main
          body in the middle of this chart indicates the range between the
          opening and closing prices. If the block in the middle is colored in
          then the currency closed lower than it opened.
        </p>
      </div>

      <hr />

      <div class="content_base_template">
        <h3>Line chart</h3>
        <p>
          Line charts are one the most basic types of charts used in finance in
          general and forex in particular. This type of chart is formed through
          a line connecting a series of data points together; usually lines are
          drawn from one closing price to the next.
          <br />
          Line charts provide a clear visualization of the general price
          fluctuation over a given period of time. One of the main reasons that
          make line charts so popular is that they record closing prices, one of
          the most important prices to keep track of.
        </p>
      </div>

      <hr />

      <div class="content_base_template">
        <h3>Technical Indicators</h3>
        <p>
          Besides studying chart patterns, there are other varied and more
          sophisticated technical tools and mathematical indicators available.
          The most commonly used are technical indicators, measuring support and
          resistance and using trend lines, although all three can be considered
          as technical indicators as they all rely on looking at the chart and
          reviewing recent history trying to spot whether a price is following a
          pattern or moving in a range.
        </p>
        <br />
        <br />
        <p>
          A technical indicator is a graphical representation resulting from
          calculations based on the price action and is usually displayed along
          the bottom of the chart. A wide range of technical indicators are
          widely used by many traders. They can be categorized according to what
          they describe and what they indicate.
        </p>
      </div>

      <hr />

      <div class="content_base_template">
        <h3>Trend indicators</h3>
        <p>
          Trend is a term used to describe the persistence of price movement in
          one direction over time. Trends move in three directions: up, down and
          sideways. Trend indicators smooth variable price data to create a
          composite of market direction.
        </p>
        <br />
        <br />
        <p>Example: Moving Averages, Trendlines</p>
      </div>

      <hr />

      <div class="content_base_template">
        <h3>Strength indicators</h3>
        <p>
          Market strength describes the intensity of market opinion with
          reference to a price by examining the market positions taken by
          various market participants. Volume and open interest are the basic
          ingredients of this indicator. Their signals are coincident or leading
          the market. Example: Volume
        </p>
      </div>

      <hr />

      <div class="content_base_template">
        <h3>Volatility indicators</h3>
        <p>
          Volatility is a general term used to describe the magnitude, or size,
          of day-to-day price fluctuations independent of their direction.
          Generally, changes in volatility tend to lead changes in prices.
        </p>
        <br />
        <br />
        <p>Example: Bollinger Bands</p>
      </div>

      <div class="content_base_template">
        <h3>Cycle indicators</h3>
        <p>
          A cycle is a term to indicate repeating patterns of market movement,
          specific to recurrent events such as seasons, elections etc. Many
          markets have a tendency to move in cyclical patterns. Cycle indicators
          determine the timing of a particular market patterns.
        </p>
        <br />
        <br />
        <p>Example: Elliott Wave</p>
      </div>

      <div class="content_base_template">
        <h3>Support &amp; Resistance indicators</h3>
        <p>
          Support and resistance describes the price levels where markets
          repeatedly rise or fall and then reverse. This phenomenon is
          attributed to basic supply and demand.
        </p>
        <br />
        <br />
        <p>Example: Trendlines</p>
      </div>

      <div class="content_base_template">
        <h3>Momentum indicators</h3>
        <p>
          Momentum is a general term used to describe the speed at which prices
          move over a given time period. Momentum indicators determine the
          strength or weakness of a trend as it progresses over time. Momentum
          is highest at the beginning of a trend and lowest at trend turning
          points. Any divergence of directions in price and momentum is a
          warning of weakness; if price extremes occur with weak momentum, it
          signals an end of movement in that direction. If momentum is trending
          strongly and prices are flat, it signals a potential change in price
          direction.
        </p>
        <br />
        <br />
        <p>Example: Stochastic, MACD, RSI</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "trust-fx-capital-technical-analysis",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
