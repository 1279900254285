<template>
  <div class="main-container header row">
    <div class="col-sm-12 button-main-container">
      <router-link
        class="btn account-button btn-danger"
        tag="button"
        to="/trustfxcapital/liveAccount"
        >Open Live Accounts</router-link
      >
      <!-- <a class="btn account-button btn-danger deposit" href="https://rzp.io/l/FZScgRK" target="_blank">Deposit
        <br>
        <div class="deposit-warning">(Only for India)</div>
      </a> -->
      <router-link
        tag="button"
        to="/trustfxcapital/withdrawals"
        class="btn account-button btn-info"
        >Withdrawals</router-link
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "trust-fx-capital-actions",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header {
  margin: 0 15% !important;
  padding: 15px;
}
.header-logo {
  width: 5rem;
}
.button-main-container {
  text-align: center;
}
.account-button {
  margin: 10px 5px;
}
.deposit {
  padding-bottom: 0;
  padding-top: 0;
}
.deposit-warning {
  font-size: 9px;
}
.logo-text {
  display: inline-block;
  font-size: 1.4rem;
  color: deepskyblue;
  font-weight: bold;
  font-style: italic;
  padding: 0.5rem;
}
@media screen and (max-width: 500px) {
    .header {
      margin: 0 !important;
    }
    .main-container {
      width: 100% !important;
    }
   .button-main-container {
     padding: 0;

     .account-button {
       margin: 5px 10px;
     }
   }
}
</style>
