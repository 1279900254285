<template>
  <div>
      <trust-fx-capital-carousel />
      <trust-fx-capital-account-and-products />
      <trust-fx-capital-partnership />
      <trust-fx-capital-partners />
      <i class="bi bi-chat-left-text"></i>
  </div>
</template>

<script>
import TrustFxCapitalCarousel from './TrustFxCapitalCarousel.vue'
import TrustFxCapitalAccountAndProducts from './TrustFxCapitalAccountAndProducts.vue'
import TrustFxCapitalPartnership from './TrustFxCapitalPartnership.vue'
import TrustFxCapitalPartners from './TrustFxCapitalPartners.vue'
// import Email from 'smtp';
export default {
  name: 'trust-fx-capital-home',
  components: {
      TrustFxCapitalCarousel,
      TrustFxCapitalPartnership,
      TrustFxCapitalPartners,
      TrustFxCapitalAccountAndProducts
  }
}
</script>
    

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>