<template>
  <div class="main-container">
    <div>
      <h2 class="main_content_heading">Profile</h2>
      <img src="../../img/profile.jpg" class="main_content_img_common" />

      <div class="content_base_template">
        <h2>Who We Are ?</h2>
        <p>
          TrustFxCapital was founded by group of traders and financial professionals
          having significant experience in the Forex, CFD and Equity markets in
          Asia, Europe and North America. This has enabled us to select the best
          solutions to accomplish our main goal- to make available transparent
          Forex trading to clients from all walks of life, easy accessibility
          and security.
          <br />
          <br />
          TrustFxCapital provide cutting edge trading solutions for all kind of
          traders as well as new comers to trading. We are proud of our training
          methodology which has helped many newcomers to become successful
          traders. We offer our clients superior execution technology, lower
          spreads and unrivaled liquidity on variety of instruments in Forex,
          Spot metals, Stocks and Futures.
        </p>
      </div>

      <div class="content_base_template">
        <h2>Our Core Values</h2>
        <ul>
          <li>Transparency</li>
          <li>Confidentiality</li>
          <li>Integrity and Trust</li>
          <li>Commitment and Responsibility</li>
          <li>Innovative Solutions</li>
          <li>Accessibility</li>
        </ul>
      </div>

      <div class="content_base_template">
        <h2>Our Business Model</h2>
        <p>
          TrustFxCapital business model allows clients to choose from a variety of
          trading accounts (STP, Micro, ECN and free unlimited demo accounts)
          and further enhance their trading capabilities with automated trading
          systems. All our clients have access to a unique Forex Trading
          environment that offers sophisticated STP (Straight through
          Processing) liquidity, execution and trading functionality. Our
          Non-dealing desk model allows client orders to be executed
          automatically and instantaneously through our Market network of
          liquidity providers
          <br />
          <br />
          TrustFxCapital also offers its own personal account management technology
          allowing our clients to benefit from the strategies of experienced
          traders with a proven track record of successful trading and
          guarantees automatic distribution of profit and loss between the
          strategy provider and the followers.
        </p>
      </div>

      <div class="content_base_template">
        <h2>Our Mission</h2>
        <p>
          TrustFxCapital mission is to create the best and most transparent trading
          environment for retail and institutional clients alike allowing
          traders to focus more on their trading. We are dedicated to provide
          all our clients a reliable and affordable service of the highest
          quality with a personal and confidential approach regardless of
          experience or the size of the investment. We have trader’s interests
          at our core and we make sure that their individual needs are always
          met as the markets continue to evolve over time.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "trust-fx-capital-profile",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
