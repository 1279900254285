import { render, staticRenderFns } from "./TrustFxCapitalBasic.vue?vue&type=template&id=42990944&scoped=true&"
import script from "./TrustFxCapitalBasic.vue?vue&type=script&lang=js&"
export * from "./TrustFxCapitalBasic.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42990944",
  null
  
)

export default component.exports