<template>
  <div class="main-container">
    <div class="contactUs">
      <h3 class="contactUs__heading">Our Contact Info :</h3>
      <!-- <div class="contactUs_keyValue">
            <p class="contactUs_key">Phone Number :</p>
            <p class="contactUs_value">+92 3132722182</p>
          </div> -->

      <div class="contactUs_keyValue">
        <p class="contactUs_key">Support Email :</p>
        <p class="contactUs_value">customersupport@trustfxcapital.in</p>
      </div>

      <!-- <div class="contactUs_keyValue">
        <p class="contactUs_key">Account Email :</p>
        <p class="contactUs_value">sales@trustfxcapital.in</p>
      </div> -->
      <div class="contactUs_keyValue row">
        <p class="contactUs_key col-sm-12">Address :</p>
        <p class="contactUs_value col-sm-6">
          TrustFxCapital Pvt. Ltd.
          <br>
          Ground Floor & First Floor, Brigade Road,
          <br>Shanthala Nagar, Ashok Nagar, Bengaluru, Karnataka 560029
          <br><br>
        </p>
        <p class="contactUs_value col-sm-6">
          The Mall, World Trade Center,
          <br>Hamdan Bin Mohammad Street,
          <br>Zone 1 - E2 - Abu Dhabi
          <br>United Arab Emirates
          <br><br>
        </p>
        <p class="contactUs_value col-sm-6">
          10 JLN Serene,
          <br>Serene Center,
          <br>Singapore 258748
          <br><br>
        </p>
        <p class="contactUs_value col-sm-6">
          36/37, Oxford Street,
          <br>Manchester M1 5EJ,
          <br>United Kingdom
          <br><br>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "trust-fx-capital-contact-us",
};
</script>
<style scoped lang="scss">
.contactUs {
  width: 40rem;
  margin-bottom: 2rem;

  .row {
    margin-left: 0;
  }
}

.contactUs__heading {
  font-size: 2.3rem;
  margin-top: 4rem;
  margin-bottom: 3rem;
}

.contactUs_keyValue {
  background: linear-gradient(to right bottom, red, pink);
  color: #fff;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-top-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.contactUs_keyValue:not(:last-child) {
  margin-bottom: 2rem;
}

.contactUs_keyValue p {
  margin: 0;
  padding: 0 1rem;
}

.contactUs_keyValue .contactUs_key {
  margin-bottom: 1rem;
  color: #222021;
  background-color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
}
  @media screen and (max-width: 500px) {
    .contactUs {
      width: 100%;
    }
  }
</style>
