<template>
    <div class="main-container">
        <div>
          <h2 class="main_content_heading">Careers</h2>
          <img src="../../img/profile.jpg" class="main_content_img_common">

          <div class="content_base_template">
            <p>
              TrustFxCapital is an equal opportunities employer and we are always
              seeking individuals of the highest calibre. Individuals who have
              the knowledge, responsibility and commitment to the same ideals we
              hold as a company. We’re looking for outstanding candidates who
              are looking for a stage to shine on and need the challenges of a
              busy corporate culture to take them to the next level in their
              professional development.
              <br>
              <br>
              A very competitive remuneration and benefits package is offered to
              all successful candidates in addition to the opportunity to work
              within an exciting and engaging work environment where you will be
              given the tools and guidance to reach your full potential.
              <br>
              <br>
            </p>
          </div>
          <hr>

          <div class="content_base_template">
            <h2>Areas of expertise we are currently looking for</h2>
            <ul>
              <li>Account Service Managers</li>
              <li>Business Development officers</li>
              <li>Client Retention</li>
              <li>Marketing Executives</li>
            </ul>
          </div>
        </div>
      </div>
</template>
<script>
export default {
  name: "trust-fx-capital-careers",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>