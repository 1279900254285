<template>
  <div class="main-container account-types">
      <h2> Account Types</h2>
    <div class="row">
      <div class="col-sm-3 card account-types__card">
        <div class="card-body">
          <h5 class="card-title">Micro</h5>
          <hr>
          <h5 class="card-title"><span class="account-types__new-item">$100</span></h5>
          <hr>
          <div v-for="item in microItems" :key="item.text">
          <p class="card-text account-types__text">
              <img
              src="../../img/check.svg"
              alt="Bootstrap"
              width="22"
              height="22"
              class="account-types__check"
            />
            {{item.text}}
          </p>
          <hr>
          </div>
          <router-link to="/trustfxcapital/liveAccount" class="btn btn-primary">Open Account</router-link>
        </div>
      </div>
      <div class="col-sm-3 card account-types__card">
        <div class="card-body">
          <h5 class="card-title">Premium <span class="account-types__new-item">(Popular)</span></h5>
          <hr>
          <h5 class="card-title"><span class="account-types__new-item">$500</span></h5>
          <hr>
          <div v-for="item in premiumItems" :key="item.text">
          <p class="card-text account-types__text">
              <img
              src="../../img/check.svg"
              alt="Bootstrap"
              width="22"
              height="22"
              class="account-types__check"
            />
            {{item.text}}
          </p>
          <hr>
          </div>
          <router-link to="/trustfxcapital/liveAccount" class="btn btn-primary">Open Account</router-link>
        </div>
      </div>
      <div class="col-sm-3 card account-types__card">
        <div class="card-body">
          <h5 class="card-title">Gold</h5>
          <hr>
          <h5 class="card-title"><span class="account-types__new-item">$2000</span></h5>
          <hr>
          <div v-for="item in goldItems" :key="item.text">
          <p class="card-text account-types__text">
              <img
              src="../../img/check.svg"
              alt="Bootstrap"
              width="22"
              height="22"
              class="account-types__check"
            />
            {{item.text}}
          </p>
          <hr>
          </div>
          <router-link to="/trustfxcapital/liveAccount" class="btn btn-primary">Open Account</router-link>
        </div>
      </div>
      <div class="col-sm-3 card account-types__card">
        <div class="card-body">
          <h5 class="card-title">Platinum</h5>
          <hr>
          <h5 class="card-title"><span class="account-types__new-item">$10000</span></h5>
          <hr>
          <div v-for="item in platinumItems" :key="item.text">
          <p class="card-text account-types__text">
              <img
              src="../../img/check.svg"
              alt="Bootstrap"
              width="22"
              height="22"
              class="account-types__check"
            />
            {{item.text}}
          </p>
          <hr>
          </div>
          <router-link to="/trustfxcapital/liveAccount" class="btn btn-primary">Open Account</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "trust-fx-capital-account-types",
  data() {
      return {
          microItems: [
              {
                  text: "Starts from 1 PIP"
              },
              {
                  text: "Minimum Deposit $10"
              },
              {
                  text: "Trade Size 0.01 Lot"
              },
              {
                  text: "Max Leverage 1:500"
              },
              {
                  text: "Hedging Allowed"
              },
              {
                  text: "Zero Commission"
              },
              {
                  text: "Personal Account Manager"
              },
              {
                  text: "Instruments - All"
              },
              {
                  text: "Spread Charges Standard Spreads"
              }
          ],
          premiumItems: [
              {
                  text: "Spreads Starts from 1 PIP"
              },
              {
                  text: "Minimum Deposit $500"
              },
              {
                  text: "Trade Size 0.01 Lot"
              },
              {
                  text: "Max Leverage 1:400"
              },
              {
                  text: "Hedging Allowed"
              },
              {
                  text: "Zero Commission"
              },
              {
                  text: "Personal Account Manager"
              },
              {
                  text: "Instruments - All"
              },
              {
                  text: "Spread Charges Pro Spreads"
              }
          ],
          goldItems: [
              {
                  text: "Spreads Starts from 0.8 PIP"
              },
              {
                  text: "Minimum Deposit $2000"
              },
              {
                  text: "Trade Size 0.01 Lot"
              },
              {
                  text: "Max Leverage 1:200"
              },
              {
                  text: "Hedging Allowed"
              },
              {
                  text: "Zero Commission"
              },
              {
                  text: "Personal Account Manager"
              },
              {
                  text: "Instruments - All"
              },
              {
                  text: "Spread Charges - Pro Spreads"
              }
          ],
          platinumItems: [
              {
                  text: "Spreads Starts from 0.5 PIP"
              },
              {
                  text: "Minimum Deposit $2500"
              },
              {
                  text: "Trade Size 0.01 Lot"
              },
              {
                  text: "Max Leverage 1:400"
              },
              {
                  text: "Hedging Allowed"
              },
              {
                  text: "Commission 10$ per lot*"
              },
              {
                  text: "Personal Account Manager"
              },
              {
                  text: "Instruments - All"
              },
              {
                  text: "Spread Charges Ultimate Spreads"
              }
          ]
      }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.account-types {
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: black;
    .col-sm-3 {
        width: 20%;
        max-width: 22%;
        margin: 0 0.5rem;
    }
    &__card:hover {
        -moz-box-shadow:    inset 0 0 10px #000000;
        -webkit-box-shadow: inset 0 0 10px #000000;
        box-shadow:         inset 0 0 10px #000000;
    }
    &__check {
    color: greenyellow;
    }
    &__text {
        font-size: 11px;
    }
    &__cancel {
        text-decoration: line-through;
        color: grey;
    }
    &__new-item {
        color: #04AA6D;
        font-weight: bold;
        font-size: 16px;
    }
}

@media screen and (max-width: 500px) {
  .account-types {
    .col-sm-3 {
        width: 90%;
        margin-bottom: 1rem;
        max-width: 100%;
    }
  }
}

</style>
