<template>
  <div class="main-container">
    <div>
      <h2 class="main_content_heading">Basic Trading Account</h2>
      <img
        src="../../img/account_types_img_1.jpg"
        class="main_content_img_common product_image"
      />

      <div class="content_base_template">
        <h3>
          You can open an individual or a joint trading account of this type
          with the below features
        </h3>
        <ul>
          <li>Start with smallest investment, minimum deposit $100</li>
          <li>Flexible leverage up to 1:500</li>
          <li>The minimum trade volume is 1 micro lot (1,000 units)</li>
          <li>
            Approximate pip value for each micro lot is $0.10 (10 cents)
          </li>
          <li>Reduced &amp; competitive spreads on all traded instruments</li>
          <li>Highly experienced client support</li>
          <li>Multi-products &amp; easy-to-use platforms</li>
          <li>
            Hedging, Trailing Stops, Expert Advisor*, &amp; Pending Orders are
            all allowed
          </li>
          <li>
            No Swaps, No Interests, &amp; No Rollovers**
          </li>
          <li>
            Dedicated services &amp; secure access through the client login
            area.
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "trust-fx-capital-basic",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>