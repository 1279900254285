<template>
  <div class="main-container">
    <div>
      <h2 class="main_content_heading">What Is Forex?</h2>

      <div class="content_base_template">
        <p>
          FX or Forex describes the Foreign Exchange Market, a marketplace where
          the world’s various currencies are traded. Its huge volume and
          fluidity made the Forex market the largest and most significant
          financial market in the world, with well over $4 trillion traded daily
          which is almost 10 times larger than the stock market. Due to the fact
          that forex currency trading has no centralized marketplace, currencies
          can be traded in whatever market is open at any given time, creating a
          great opportunity for traders to buy and sell currencies around the
          clock 24 hours a day, 5 days a week with the exception of weekends.

          <br />
          <br />
          The major participants of the Forex market are commercial and central
          banks, large corporations and hedge-funds. However, you do not need to
          have millions or thousands of dollars to start! Due to leverage and
          marginal trading, you can start trading with $100 or $500 and enjoy
          the same trading conditions as the large market players.<br />
          The recipe for success is to buy it at the cheapest price and then
          sell at a higher price. Or the other way round – sell at a higher
          price and then buy cheaper. Whether a currency is increasing or
          declining in value, there is always a way for you to make money in
          Forex. Knowing the right time to buy or sell will do the trick. This
          is where market analytics, indicators, signals and automated trading
          systems come in handy.
        </p>
      </div>
      <hr />

      <div class="content_base_template">
        <h3>Let’s consider an example:</h3>
        <p>
          Currencies are traded in pairs and first part of pair is called base
          currency i.e. Euro against US Dollar (EUR/USD). A Forex transaction
          involves buying one currency and selling the other currency at the
          same time. The exchange rate reflects the value of one currency
          against the other currency.

          <br />
          <br />
          Let’s assume the market forecast the Euro is going to appreciate
          against US Dollar (bullish trend for EUR/USD). You decide to buy Euros
          with USD (buy order on EUR/USD). After some time you decide to sell
          the Euro at a higher price (close the open EUR/USD position). Your
          profit is the difference between the opening and the closing prices.
        </p>
        <br />
        <br />
        <h3>Advantages of Forex</h3>
        <p>
          Some of the advantages of forex trading are listed below, which
          explains why forex is fastest growing market in the world.
        </p>
      </div>
      <hr />

      <div class="content_base_template">
        <h3>24/5 Hour Market</h3>
        <p>
          The main advantage of forex (foreign exchange) is that is open around
          the clock 24 hours as day 5 days a week, enabling traders to buy and
          sell form Sunday night to Friday night. A true 24-hour market, Forex
          trading begins each day in Sydney, and moves around the globe as the
          business day begins in each financial center, first to Tokyo, London,
          and New York. The greatest liquidity occurs when multiple time zones
          overlap.
        </p>
      </div>

      <hr />

      <div class="content_base_template">
        <h3>High Liquidity</h3>
        <p>
          One of the main benefits of the forex market is its superior
          liquidity. The foreign exchange market is the most liquid market in
          the world. This is one of the main differentiating factors between the
          forex market and other financial markets. In Forex there are always
          traders who are willing to buy or sell. The market never sleeps.
        </p>
      </div>

      <hr />

      <div class="content_base_template">
        <h3>Minimum Investment</h3>
        <p>
          Generally the amount required to trade forex is lower than what would
          be required to enter into other financial markets. Leveraged (or
          marginal) trading used in Forex lets you operate funds many times as
          large as your margin deposit
        </p>
      </div>

      <hr />

      <div class="content_base_template">
        <h3>Leverage</h3>
        <p>
          Forex is typically traded on leverage. Leverage allows means that a
          lower initial outlay is required to control a larger position. For
          example if a trader had $200 in your trading account and had leverage
          of 500:1 the trader would be able to open a position with a value of
          $100,000.
        </p>
      </div>

      <hr />

      <div class="content_base_template">
        <h3>Trade both Rising and Falling Markets</h3>
        <p>
          In Forex, there is always a chance to earn as you can trade in any
          direction. This means that if you believe that a currency pair is
          going to increase in value you can buy it or ‘go long’. Similarly, if
          you believed that the pair was going to decrease in value you could
          sell it, or ‘go short’.
        </p>
      </div>

      <hr />

      <div class="content_base_template">
        <h3>Non Standardized contract sizes</h3>
        <p>
          Forex is an Over the Counter market which means that the contract
          sizes can be determined by the broker rather than an exchange. This
          means Forex traders have no fixed lot size and can trade any amount
          between 0.01 lots (1 micro lot) and 1 lot (100,000 units). This gives
          trades a greater ability to manage their risk.
        </p>
      </div>

      <hr />

      <div class="content_base_template">
        <h3>Automated trading</h3>
        <p>
          You do not have to spend long hours in front of your computer studying
          charts and following all the price movements. With automatic
          indicators and signals you will be notified immediately of any
          important events or trend reversals. You can also take advantage of
          expert advisors, that are based on your own or somebody else’s proven
          trading strategy. An Expert Advisor trades automatically without your
          participation
        </p>
      </div>

      <hr />

      <div class="content_base_template">
        <h3>Transparency</h3>
        <p>
          In some exchange based markets, larger players have been known to move
          stock or commodity in order to gain an advantage. Given the deep
          liquidity in the foreign exchange market is it almost impossible to
          interfere with general market forces.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "trust-fx-capital-what-is-forex",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
