<template>
  <div class="main-container">
    <div>
      <h2 class="main_content_heading">Spot Metals</h2>
      <img src="../../img/spot_metals.jpg" class="product_image" />

      <div class="content_base_template">
        <p>
          Trading precious metals such as gold and silver presents a very unique
          investment opportunity offering the chance to diversify and extend
          your portfolio. During market volatility or uncertain political or
          economic times, many traders choose to diversify their risk by trading
          gold/silver and to create a well balanced trading portfolio.
        </p>
        <br />
        <br />
        <p>
          Trading metal is also considered as safe investment during uncertain
          market conditions when value of metal such as gold typically tends to
          fluctuate and such volatile market movements may present trading
          opportunities that hold potential significant returns.
        </p>
        <br />
        <br />
        <p>
          Many investors also utilise metal trading for hedging opportunities,
          as trading metal creates great opportunities for hedging in every
          liquid market, with investors gaining more exposure with limited risk.
        </p>
        <br />
        <br />
        <p>
          Metals trading operate in the same way as forex trading. You can trade
          gold just like trading currency pairs; you can take a long or short
          position. TrustFxCapital offers the best conditions for precious metal
          trading by providing considerable leverage and expert support.
        </p>
        <br />
        <br />
        <p>
          Start trading gold, silver or any other precious metals online today
          by simply opening up an account with us, or contact us for further
          information.
        </p>
      </div>

      <div class="content_base_template">
        <h2 class="template_product_heading">Gold Trading Example</h2>
        <h3>Buying XAU/USD</h3>
        <br />
        <p>Leverage 1:100</p>
        <br />
        <h3>Opening the Position</h3>
        <br />
        <p>
          Opening price of the GOLD against the US Dollar (XAU/USD) is 1250.00
        </p>
        <br />
        <p>
          You decide to buy 1 standard lot (the equivalent of 100 ounces) at
          1250.00
        </p>
        <br />
        <p>
          Margin required to open the position is 1*100*1250.00*1/100=USD $1250
        </p>
        <br />
        <h3>Closing the Position</h3>
        <br />
        <p>
          Closing Price of the GOLD against the US Dollar (XAU/USD) is 1274.50
        </p>
        <br />
        <p>
          One week later the Gold has risen against the USD to 1274.50, you
          decide to take your profit by closing your buying position
        </p>
        <br />
        <p>Market movement= 1274.50 – 1250.00 = 2450 ticks</p>
        <br />
        <p>Gross profit on Trade = USD $ 1*2450 = USD$ 2450</p>
        <br />
      </div>
      <br />
      <hr />
      <table class="table_basic_metal">
        <tbody>
          <tr>
            <th>Spot Metal</th>
            <th>Symbol</th>
            <th>Type</th>
            <th>Lot Size</th>
          </tr>
          <tr>
            <td>Spot Gold</td>
            <td>XAUUSDi</td>
            <td>Spot</td>
            <td>100 Ounces</td>
          </tr>
          <tr>
            <td>Spot Silver</td>
            <td>XAGUSDi</td>
            <td>Spot</td>
            <td>5000 Ounces</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: "trust-fx-capital-spot-metal",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
