<template>
  <div class="main-container">
    <div>
      <h2 class="main_content_heading">Fundamental Analysis</h2>

      <div class="content_base_template">
        <p>
          Fundamental analysis is a method that attempts to predict the
          intrinsic value of an investment. It is based on the theory that the
          market price of an asset tends to move towards its ‘real value’ or
          ‘intrinsic value’. Fundamental traders believe that the markets will
          react to events in certain ways and that they can predict future
          market prices based on these events.

          <br />
          <br />
          Fundamental analysis in Forex entails predicting the price valuation
          of a currency and its market trends by analyzing current economic
          conditions, government policy and societal factors within a business
          cycle framework.

          <br />
          <br />
          Forex Traders gauge a country’s economic state by examining
          macroeconomic indicators. When properly used, these indicators can be
          an invaluable resource for any Forex trader. After publication of
          these indicators we can observe volatility of the market. The degree
          of volatility is determined depending on the importance of an
          indicator. That is why it is important to understand which indicator
          is important and what it represents.
          <br />
          <br />
          When properly used, these indicators can be an invaluable resource for
          any Forex trader. After publication of these indicators we can observe
          volatility of the market. The degree of volatility is determined
          depending on the importance of an indicator. That is why it is
          important to understand which indicator is important and what it
          represents.
          <br />
          <br />
          Some important economic indicators are;
        </p>
      </div>
      <hr />

      <div class="content_base_template">
        <h3>Interest Rates Announcement</h3>
        <p>
          Interest rates play the most important role in moving the prices of
          currencies in the foreign exchange market. Interest rates dictate
          flows of investment. Since currencies are the representations of a
          country’s economy, differences in interest rates affect the relative
          worth of currencies in relation to one another. When central banks
          change interest rates they cause the forex market to experience
          movement and volatility and accurate speculation of central banks’
          actions can enhance the trader’s chances for a successful trade.
        </p>
      </div>
      <hr />

      <div class="content_base_template">
        <h3>Gross Domestic Product (GDP)</h3>
        <p>
          The GDP is the broadest measure of a country’s economy, and it
          represents the total market value of all goods and services produced
          in a country during a given year. Since the GDP figure itself is often
          considered a lagging indicator, most traders focus on the two reports
          that are issued in the months before the final GDP figures: the
          advance report and the preliminary report. Significant revisions
          between these reports can cause considerable volatility.
        </p>
      </div>

      <hr />

      <div class="content_base_template">
        <h3>Consumer Price Index</h3>
        <p>
          The Consumer Price Index (CPI) is probably the most crucial indicator
          of inflation. It represents changes in the level of retail prices for
          the basic consumer basket. If the economy develops in normal
          conditions, the increase in CPI can lead to an increase in basic
          interest rates. This, in turn, leads to an increase in the
          attractiveness of a currency.
        </p>
      </div>

      <hr />

      <div class="content_base_template">
        <h3>Employment Indicators</h3>
        <p>
          Employment indicators reflect the overall health of an economy or
          business cycle. In order to understand how an economy is functioning,
          it is important to know how many jobs are being created or destructed,
          what percentage of the work force is actively working, and how many
          new people are claiming unemployment.
        </p>
      </div>

      <hr />

      <div class="content_base_template">
        <h3>Retail Sales</h3>
        <p>
          The retail sales indicator is released on a monthly basis and is
          important to the foreign exchange trader because it shows the overall
          strength of consumer spending and the success of retail stores. It can
          be used to predict the performance of more important lagging
          indicators, and to assess the immediate direction of an economy.
        </p>
      </div>

      <hr />

      <div class="content_base_template">
        <h3>Balance of Payments</h3>
        <p>
          The Balance of Payments represents the ratio between the amount of
          payments received from abroad and the amount of payments going abroad.
          If coming payment exceeds payments to other countries and
          international organizations the balance of payments is positive. The
          surplus is a favorable factor for growth of the national currency.
        </p>
      </div>

      <hr />

      <div class="content_base_template">
        <h3>Government Fiscal and Monetary policy</h3>
        <p>
          Stabilization of the economy (e.g., full employment, control of
          inflation, and an equitable balance of payments) is one of the goals
          that governments attempt to achieve through manipulation of fiscal and
          monetary policies. Fiscal policy relates to taxes and expenditures,
          monetary policy to financial markets and the supply of credit, money,
          and other financial assets.
          <br />
          <br />
          There are many economic indicators, and even more private reports that
          can be used to evaluate the fundamentals of forex. It’s important to
          take the time to not only look at the numbers, but also understand
          what they mean and how they affect a nation’s economy.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "trust-fx-capital-fundamentals",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
