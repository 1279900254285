import Vue from 'vue';
import TrustFxCapitalProfile from '../components/about/TrustFxCapitalProfile.vue';
import TrustFxCapitalCareers from '../components/about/TrustFxCapitalCareers.vue';
import TrustFxCapitalWhyUs from '../components/about/TrustFxCapitalWhyUs.vue';

import TrustFxCapitalAccountTypes from '../components/accountTypes/TrustFxCapitalAccountTypes.vue';
import TrustFxCapitalBasic from '../components/accountTypes/TrustFxCapitalBasic.vue';
import TrustFxCapitalPremium from '../components/accountTypes/TrustFxCapitalPremium.vue';
import TrustFxCapitalVip from '../components/accountTypes/TrustFxCapitalVip.vue';

import TrustFxCapitalFundamental from '../components/learnForex/TrustFxCapitalFundamental.vue';
import TrustFxCapitalHowToStart from '../components/learnForex/TrustFxCapitalHowToStart.vue';
import TrustFxCapitalMarketHours from '../components/learnForex/TrustFxCapitalMarketHours.vue';
import TrustFxCapitalTechnicalAnalysis from '../components/learnForex/TrustFxCapitalTechnicalAnalysis.vue';
import TrustFxCapitalWhatIsForex from '../components/learnForex/TrustFxCapitalWhatIsForex.vue';

import TrustFxCapitalCommodities from '../components/products/TrustFxCapitalCommodities.vue';
import TrustFxCapitalForex from '../components/products/TrustFxCapitalForex.vue';
import TrustFxCapitalIndices from '../components/products/TrustFxCapitalIndices.vue';
import TrustFxCapitalShares from '../components/products/TrustFxCapitalShares.vue';
import TrustFxCapitalSpotMetals from '../components/products/TrustFxCapitalSpotMetals.vue';

import TrustFxCapitalLiveAccount from '../components/TrustFxCapitalLiveAccount.vue';
import TrustFxCapitalWithdrawals from '../components/TrustFxCapitalWithdrawals.vue';
import TrustFxCapitalContactUs from '../components/TrustFxCapitalContactUs.vue';

import TrustFxCapitalHome from '../components/home/TrustFxCapitalHome.vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
    mode: "history",
    routes: [
        {
            path: '/',
            redirect: '/trustfxcapital/'
        },
        {
            path: '/trustfxcapital/',
            component: TrustFxCapitalHome
        },
        {
            path: '/trustfxcapital/profile',
            component: TrustFxCapitalProfile
        },
        {
            path: '/trustfxcapital/whyUs',
            component: TrustFxCapitalWhyUs
        },
        {
            path: '/trustfxcapital/careers',
            component: TrustFxCapitalCareers
        },
        {
            path: '/trustfxcapital/accountTypes',
            component: TrustFxCapitalAccountTypes
        },
        {
            path: '/trustfxcapital/basic',
            component: TrustFxCapitalBasic
        },
        {
            path: '/trustfxcapital/premium',
            component: TrustFxCapitalPremium
        },
        {
            path: '/trustfxcapital/vip',
            component: TrustFxCapitalVip
        },
        {
            path: '/trustfxcapital/fundamental',
            component: TrustFxCapitalFundamental
        },
        {
            path: '/trustfxcapital/howToStart',
            component: TrustFxCapitalHowToStart
        },
        {
            path: '/trustfxcapital/technicalAnalysis',
            component: TrustFxCapitalTechnicalAnalysis
        },
        {
            path: '/trustfxcapital/marketHours',
            component: TrustFxCapitalMarketHours
        },
        {
            path: '/trustfxcapital/whatIsForex',
            component: TrustFxCapitalWhatIsForex
        },
        {
            path: '/trustfxcapital/commodities',
            component: TrustFxCapitalCommodities
        },
        {
            path: '/trustfxcapital/forex',
            component: TrustFxCapitalForex
        },
        {
            path: '/trustfxcapital/indices',
            component: TrustFxCapitalIndices
        },
        {
            path: '/trustfxcapital/shares',
            component: TrustFxCapitalShares
        },
        {
            path: '/trustfxcapital/spotMetals',
            component: TrustFxCapitalSpotMetals
        },
        {
            path: '/trustfxcapital/liveAccount',
            component: TrustFxCapitalLiveAccount
        },
        {
            path: '/trustfxcapital/withdrawals',
            component: TrustFxCapitalWithdrawals
        },
        {
            path: '/trustfxcapital/contactUs',
            component: TrustFxCapitalContactUs
        }
    ]
});