<template>
  <section class="trust-fx-capital-partnership">
    <div class="trust-fx-capital-partnership__header">
      <h1>TrustFxCapital Partnership Programs</h1>
    </div>
    <div class="trust-fx-capital-partnership__body">
      <p>
        Expand your revenue and business by benefiting from TrustFxCapital
        Partnership Programs.<br />
        Our unique programs are suitable for Individuals and Corporatess
      </p>
    </div>
    <div class="trust-fx-capital-partnership__button">
      <button type="button" class="btn btn-light btn-lg">Know More</button>
    </div>
  </section>
</template>
<script>
export default {
  name: "trust-fx-capital-partnership",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.trust-fx-capital-partnership {
  text-align: center;
  background: linear-gradient(
      to right bottom,
      rgba(83, 105, 118, 0.85),
      rgba(34, 32, 33, 0.85)
    ),
    url(../../img/partnership.jpeg);
  background-position: center;
  background-size: cover;
  color: white;
  padding: 50px 0;

  &__header {
    margin-bottom: 20px;
  }

  &__body {
    margin: 20px 0;
  }
}
</style>
