<template>
  <div class="main-container header">
      <router-link to="/trustfxcapital">
        <!-- <img class="header-logo" src="../img/TrustFxCapital_logo.jpg" /> -->
        <div class="logo-text">TrustFxCapital</div>
      </router-link>
  </div>
</template>
<script>
export default {
  name: "trust-fx-capital-header",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header {
  margin: 0 !important;
  width: 100% !important;
  text-align: center;
  padding: 15px;

}
.header-logo {
  width: 5rem;
}
.button-main-container {
  text-align: center;
}
.account-button {
  margin: 10px 5px;
}
.deposit {
  padding-bottom: 0;
  padding-top: 0;
}
.deposit-warning {
  font-size: 9px;
}
.logo-text {
  display: inline-block;
  font-size: 2.8rem;
  color: deepskyblue;
  font-weight: bold;
  font-style: italic;
  padding: 0.5rem;
}
@media screen and (max-width: 500px) {
    .header {
      margin: 0 !important;
    }
    .main-container {
      width: 100% !important;
    }
   .button-main-container {
     padding: 0;

     .account-button {
       margin: 5px 10px;
     }
   }
}
</style>
