import Vue from "vue";
import App from "./App.vue";
import router from "./router";
 
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import 'jquery/src/jquery.js';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

Vue.config.productionTip = false;

let render;
render = (h) => h(App);
new Vue({
  el: "#app",
  router,
  render,
});