<template>
  <div class="main-container">
    <div>
      <h2 class="main_content_heading">Indices</h2>
      <img src="../../img/indices.jpg" class="product_image" />

      <div class="content_base_template">
        <p>
          Indices or Indexes are groupings of a specified number of stocks into
          one trade-able entity, either representing a particular market or
          portion of it. Although each stock can be bought and sold on its own,
          and index can also be traded as a whole.Indices are bought and sold in
          same way as an individual share.
          <br />
          <br />
          Indices are mostly region based and investors also use them to gauge a
          particular economy’s health. Trading on Indices also allows investors
          to diversify their risk across the whole market instead of investing
          in a specific company. Major Indices are S&amp;P 500, Dow Jones in
          USA, FTSE100 in UK, European indices such as DAX30 and Asian Indices
          such as NIkkei225, Shanghai Composite.

          <br />
          <br />
          Most of Indices are calculated using a market
          value/capitalisation-weighted average, which means the size of each
          company, is taken into account. The more a particular company is
          worth, the more its share price will affect the index as a whole.
          Example:FTSE100 However,Price-weighted indices base the weighting that
          a company has in the index on the company’s share price.Example: Dow
          Jones and Nikkei
          <br />
          <br />
          Indices trading is available through TrustFxCapital trading platforms,
          providing its clients with the lowest commissions and margin
          requirements so as to maximize your profits.
          <br />
          <br />
          Start trading on indices online today by simply opening up an account
          with us, or contact us for further information.
        </p>
      </div>
      <hr />

      <div class="content_base_template">
        <h2 class="template_product_heading">Indices Trading Example</h2>
        <h3>Buying SPX500</h3>
        <br />
        <h3>Opening the PositionD</h3>
        <br />
        <p>
          Opening price USD $ 2082.60
        </p>
        <br />
        <p>
          You decide to buy 1 contract at $ 2082.60 (1 contract=1$ per index
          point)
        </p>
        <br />
        <h3>Closing the Position</h3>
        <p>
          One week later the SPX500 has risen to $2092.60, you decide to take
          your profit by closing your buying position
        </p>
        <p>Market movement= 2092.60 – 2082.60 = 10</p>
        <p>
          Gross profit on Trade = 2092.60*1-2082.60*1= USD$ 10 ($1 per Index
          point)
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "trust-fx-capital-indices",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
