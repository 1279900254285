<template>
  <div class="main-container">
    <div>
      <h2 class="main_content_heading">Shares</h2>
      <img src="../../img/shares.jpg" class="product_image" />

      <div class="content_base_template">
        <p>
          The best feature of the TrustFxCapital Market is the vast range of
          services that we are currently offering to our customers. Be it
          trading in currency pair, in commodities or in shares, we have been
          continuously expanding our market range and currently offer shares of
          almost every top Multinational Companies with no commission fees.

          <br />
          <br />
          If you want to trade the big names such as Google, Amazon etc then
          consider trading Shares CFD’s with us. With TrustFxCapital you can buy and
          sell shares of some of the most influential companies listed on NYSE,
          NASDAQ, LSE and other European Stock exchanges.

          <br />
          <br />
          Trading on Shares let you capitalize on the changes in an individual
          share price both quickly and easily, whether you are Buying or
          Selling.

          <br />
          <br />
          Some of our most popular traded stocks are; Amazon, Google, McDonalds,
          Ebay, Alibaba, Facebook.
        </p>
      </div>
      <hr />

      <div class="content_base_template">
        <h2 class="template_product_heading">
          Advantages of Trading Shares with TrustFxCapital
        </h2>
        <br />
        <p>
          – Fast Execution and strong Liquidity
        </p>
        <br />
        <p>
          – Leveraged Trading and Bonus Offers to boost your trading capability
        </p>
        <br />
        <p>
          – Trade multiple products with one account, no need for separate
          account for Shares trading
        </p>
        <br />
        <p>– Access to the world’s top traded Shares</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "trust-fx-capital-shares",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
