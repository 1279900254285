<template>
  <div>
    <div
      class="modal fade"
      id="feedbackModal"
      tabindex="-1"
      data-keyboard="false"
      data-backdrop="static"
      role="dialog"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Success</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              @click="displaySuccess = false"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Thank you for your submission.</p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showGetCall"
      class="modal modal-active"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Request a call</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="showGetCall = false"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="modal-field">
              <input
                type="text"
                class="form-control"
                placeholder="Name *"
                v-model="name"
              />
            </div>
            <div class="modal-field">
              <input
                type="text"
                class="form-control"
                placeholder="Email *"
                v-model="email"
              />
            </div>
            <div class="modal-field">
              <input
                type="text"
                class="form-control"
                placeholder="Phone Number *"
                v-model="phoneNo"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              :disabled="
                (name == '' || email == '' || phoneNo == '') && !displaySuccess
              "
              data-toggle="modal"
              data-target="#feedbackModal"
              @click="requestACall"
              class="btn btn-primary"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showFeedback"
      class="modal modal-active"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Feedback</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="showFeedback = false"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="modal-field">
              <input
                type="text"
                class="form-control"
                placeholder="Name *"
                v-model="feedbackName"
              />
            </div>
            <div class="modal-field">
              <input
                type="text"
                class="form-control"
                placeholder="Email *"
                v-model="feedbackEmail"
              />
            </div>
            <div class="modal-field">
              <input
                type="text"
                class="form-control"
                placeholder="Feedback *"
                v-model="feedback"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              :disabled="
                (feedbackName == '' || feedbackEmail == '' || feedback == '') &&
                  !displaySuccess
              "
              data-toggle="modal"
              data-target="#feedbackModal"
              @click="feedbackRequest"
              class="btn btn-primary"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="side_nav">
      <ul class="side_nav__floating_btns">
        <!-- <li class="floating_btns_item">
          <a @click="showGetCall = true"
            ><img
              src="../img/telephone.svg"
              alt="Bootstrap"
              width="16"
              height="16"
            /><br />Get A Call</a
          >
        </li>
        <li class="floating_btns_item">
          <a @click="showFeedback = true"
            ><img
              src="../img/menu-up.svg"
              alt="Bootstrap"
              width="16"
              height="16"
            /><br />Feedback</a
          >
        </li> -->
        <li class="floating_btns_item">
          <router-link class="contact-us" to="/trustfxcapital/contactUs">
            <img
              src="../img/headphones.svg"
              alt="Bootstrap"
              width="16"
              height="16"
            /><br />Contact us</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "trust-fx-capital-side-nav",
  data() {
    return {
      showGetCall: false,
      showFeedback: false,
      name: "",
      email: "",
      phoneNo: "",
      feedback: "",
      feedbackEmail: "",
      feedbackName: "",
      displaySuccess: false,
    };
  },
  beforeCreate() {
    let externalScript = document.createElement("script");
    externalScript.setAttribute("src", "https://smtpjs.com/v3/smtp.js");
    document.head.appendChild(externalScript);
  },
  methods: {
    requestACall() {
      const body = `Name: ${this.name}<br />
                        Email: ${this.email}<br />
                        Phone No: ${this.phoneNo}<br />`;
      if (window.Email) {
        window.Email.send({
          Host: "smtp.gmail.com",
          Username: "TrustFxCapital2@gmail.com",
          Password: "TrustFxCapital@02",
          To: "support@trustfxcapital.in",
          From: "TrustFxCapital2@gmail.com",
          Subject: "Get A Call",
          Body: body,
        });
        this.displaySuccess = true;
        this.showGetCall = false;
        this.resetForm();
      }
    },
    feedbackRequest() {
      const body = `Name: ${this.feedbackName}<br />
                        Email: ${this.feedbackEmail}<br />
                        Phone No: ${this.feedback}<br />`;
      if (window.Email) {
        window.Email.send({
          Host: "smtp.gmail.com",
          Username: "TrustFxCapital2@gmail.com",
          Password: "TrustFxCapital@02",
          To: "support@trustfxcapital.in",
          From: "TrustFxCapital2@gmail.com",
          Subject: "Feedback",
          Body: body,
        });
        this.displaySuccess = true;
        this.showFeedback = false;
        this.resetFeedbackForm();
      }
    },
    resetFeedbackForm() {
      this.feedbackName = "";
      this.feedbackEmail = "";
      this.feedback = "";
    },
    resetForm() {
      this.name = "";
      this.email = "";
      this.phoneNo = "";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.side_nav {
  position: fixed;
  color: #fff;
  font-size: 12px;
  top: 40%;
  right: 0;
  z-index: 100;
}

.side_nav__floating_btns {
  list-style-type: none;
  text-align: center;
  padding: 0;
}

.floating_btns_item a {
  display: inline-block;
  margin-bottom: 20px;
  padding: 5px;
  border-top-left-radius: 0.6rem;
  border-bottom-left-radius: 0.6rem;
  box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.2);
}

.floating_btns_item a:link,
.floating_btns_item a:visited {
  text-decoration: none;
  color: #fff;
}

.floating_btns_item:first-child a {
  background-color: #00cdac;
}

.floating_btns_item:nth-child(2) a {
  background-color: #bd0a0a;
}

.floating_btns_item:last-child a {
  background-color: #00cdac;
}

.floating_btns_item ion-icon {
  font-size: 2.5rem;
  color: #fff;
}
.modal-active {
  display: block !important;
  opacity: 1;
}
.modal-field {
  margin: 1rem 0;
}
.modal-alert {
  top: 20%;
  position: fixed !important;
  left: 10%;
  width: 80%;
  z-index: 100;
}
.close {
  float: right;
}
</style>
