<template>
  <div class="main-container">
    <div>
      <h2 class="main_content_heading">Commodities</h2>
      <img src="../../img/commodities.jpg" class="product_image" />

      <div class="content_base_template">
        <p>
          Despite offering various currency pairs ranging from major to exotic,
          Forex Market also offers traders to trade in various commodities such
          as energy (oil, natural gas etc), Soft Commodities ( Wheat, Coffee,
          Sugar etc).
        </p>
        <br />
        <br />
        <p>
          It is hard to believe any sector in the global market that does not
          get influenced due to price changes in commodities most significantly,
          Oil or “Black Gold”. These changing prices whether going up or down
          ultimately lead to create huge investment options. Trading in oil,
          whether it’s Crude Oil (WTI or US Oil) which is priced in US dollars
          or Brent Oil (XTI or UK Oil) priced in Pound Sterling, is a bit
          similar to trade in currencies. Exchange rates of respective currency
          are responsible for fluctuation in prices, then whether it’s WTI or
          XTI.
        </p>
        <br />
        <br />
        <p>
          TrustFxCapital with its best in the market trading platform, 24X7
          technical support and highly trained specialists offers its clients to
          trade in commodities by simply opening a trading account with them and
          finally making higher earning potentials to its clients.
        </p>
        <br />
      </div>
      <hr />

      <div class="content_base_template">
        <h2 class="template_product_heading">Oil Trading Example</h2>
        <p>
          Trading in oil is quite similar to trading in currencies. Oil prices
          are displayed as the price of one barrel of oil is US dollars. Most
          trading platforms consider a pip in crude oil to be $0.01
        </p>
        <h3>Buying US Oil, WTI (Crude Oil)</h3>
        <br />
        <p>Leverage 1:100</p>
        <br />
        <h3>Opening the Position</h3>
        <br />
        <p>Let’s take opening price at 65.10</p>
        <br />
        <p>
          You decide to buy/go long 1 standard lot (1,000 barrels) at 65.10
        </p>
        <br />
        <h3>Closing the Position</h3>
        <br />
        <p>
          Closing Price of the GOLD against the US Dollar (XAU/USD) is 1274.50
        </p>
        <br />
        <p>Market Movement: – 66.10-65.10= 100 points</p>
        <br />
        <p>1 Standard Lot 1 Point is $10</p>
        <br />
        <p>Gross Profit on Trade= USD $10*100= USD $1,000</p>
        <br />
      </div>
      <br />
    </div>
  </div>
</template>
<script>
export default {
  name: "trust-fx-capital-commodities",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
