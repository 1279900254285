<template>
  <div class="main-container">
    <div>
      <h2 class="main_content_heading">Market Hours</h2>

      <div class="content_base_template">
        <p>
          The Forex market is open 24 hours a day, and it is important to know
          which is the most active trading periods in which you can trade your
          chosen currency pairs profitably.
          <br />
          <br />
          Trading sessions are periods of time when banks are actively trading.
          As is well-known, Forex working hours are unlimited and it functions
          round the clock. Sessions come one after another, or partially
          superimpose on one another, giving traders a possibility to trade
          whenever convenient to them.
          <br />
          To know the schedule of trading sessions on the Forex market is also
          useful because currencies behave differently according to different
          trading sessions. For instance, if we take a less active period after
          New York closes and before Tokyo opens, Sydney will be open for
          trading but with more modest activity than the three major sessions
          (London, US, Tokyo). Consequently, less activity means less financial
          opportunity. If you want to trade currency pairs like EUR/USD, GBP/USD
          or USD/CHF you will find more activity when both Europe and the United
          States are active.
        </p>
      </div>
      <br />

      <hr />
      <table class="table_basic_metal table_market_hours">
        <tbody>
          <tr>
            <th>Region</th>
            <th>City</th>
            <th>Open (GMT)</th>
            <th>Close (GMT)</th>
          </tr>
          <tr>
            <th rowspan="2">Asia</th>
            <td>Tokyo</td>
            <td>12:00 am</td>
            <td>09:00 am</td>
          </tr>
          <tr>
            <td>Hong Kong</td>
            <td>01:00 am</td>
            <td>10:00 am</td>
          </tr>
          <tr>
            <th rowspan="2">Europe</th>
            <td>Frankfurt</td>
            <td>07:00 am</td>
            <td>04:00 pm</td>
          </tr>
          <tr>
            <td>London</td>
            <td>08:00 am</td>
            <td>05:00 pm</td>
          </tr>

          <tr>
            <th rowspan="2">America</th>
            <td>New York</td>
            <td>01:00 am</td>
            <td>10:00 pm</td>
          </tr>
          <tr>
            <td>Chicago</td>
            <td>02:00 am</td>
            <td>11:00 pm</td>
          </tr>

          <tr>
            <th rowspan="2">Wellington</th>
            <td>New York</td>
            <td>10:00 pm</td>
            <td>06:00 am</td>
          </tr>
          <tr>
            <td>Sydney</td>
            <td>10:00 pm</td>
            <td>07:00 am</td>
          </tr>
        </tbody>
      </table>

      <br />
      <br />
      <hr />

      <div class="content_base_template">
        <h3>Trading Sessions</h3>
        <p>
          Below is a brief of trading sessions that will help you make the most
          of the market.
        </p>
      </div>
      <hr />

      <div class="content_base_template">
        <h3>Asian Session</h3>
        <p>
          During this forex trading hours the most active deals in the market
          exchange operations are the US dollar against the yen (USDJPY), the
          euro against the yen (EURJPY), the US dollar against the euro (EURUSD)
          and the Australian dollar against the US dollar (AUDUSD).
        </p>
      </div>
      <hr />

      <div class="content_base_template">
        <h3>European Session</h3>
        <p>
          At this time the trades are conducted in the European financial
          centers. The volatility of the most popular currency pairs increases
          significantly after the London forex online market starts its session.
          Changes of rates during the European trading hours could be
          significant, because the majority of monetary stock is concentrated in
          Europe. EUR, GBP, USD are the most active.
        </p>
      </div>
      <hr />

      <div class="content_base_template">
        <h3>US Session</h3>
        <p>
          Forex trading activity reaches its maximum level when the New York
          session opens and US banks begin to work, and European dealers return
          after their lunch break. The influence of European and American banks
          is similar, so no significant changes take place in Forex compared to
          the start of the European session. However, after the termination of
          the European market volatility may be higher. Often this is observed
          on Friday, before the weekend. The American session is more aggressive
          in trade than others and USD, EUR, GBP, AUD, JPY are the most active.
        </p>
      </div>
      <br />
    </div>
  </div>
</template>
<script>
export default {
  name: "trust-fx-capital-market-hours",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
