<template>
  <div class="main-container">
    <div
      class="modal fade"
      id="liveAccountModal"
      tabindex="-1"
      data-keyboard="false"
      data-backdrop="static"
      role="dialog"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Success</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              @click="displaySuccess = false"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              Thank you for submitting the details. We will open the account
              shortly.
            </p>
          </div>
        </div>
      </div>
    </div>
    <h2 class="main_content_heading">Registeration (Live Account)</h2>
    <form>
      <div class="form__title">Information</div>
      <div class="row">
        <div class="col-sm-6 form-group form__register col-custom">
          <select v-model="selectedCountry">
            <option disabled value="selected">Please select the country</option>
            <option
              v-for="country in countries"
              :key="country.id"
              :value="country.name"
              >{{ country.name }}
            </option>
          </select>
        </div>
        <div class="col-sm-6 form-group form__register col-custom">
          <select v-model="selectedTitle">
            <option disabled value="selected">Title</option>
            <option value="Mr.">Mr.</option>
            <option value="Mrs.">Mrs.</option>
            <option value="Miss.">Miss.</option>
          </select>
        </div>
        <div class="col-sm-6 form-group form__register col-custom">
          <input
            type="text"
            class="form-control"
            placeholder="First Name *"
            v-model="firstName"
          />
        </div>
        <div class="col-sm-6 form-group form__register col-custom">
          <input
            type="text"
            class="form-control"
            placeholder="Last Name *"
            v-model="lastName"
          />
        </div>
        <div class="col-sm-6 form-group form__register col-custom">
          <input
            type="text"
            class="form-control"
            placeholder="Email Address *"
            v-model="emailAddress"
          />
        </div>
        <div class="col-sm-6 form-group form__register col-custom">
          <input
            type="text"
            class="form-control"
            placeholder="Alternate Email Address"
            v-model="alternateEmailAddress"
          />
        </div>
        <div class="col-sm-6 form-group form__register col-custom">
          <input
            type="text"
            class="form-control"
            placeholder="Date Of birth *"
            v-model="dateOfBirth"
          />
        </div>
        <div class="col-sm-6 form-group form__register col-custom">
          <input
            type="text"
            class="form-control"
            placeholder="Phone Number *"
            v-model="phoneNo"
          />
        </div>
      </div>
    </form>
    <div class="action">
      <button type="reset" @click="resetForm" class="btn btn-secondary button">
        Reset
      </button>
      <button
        :disabled="
          (selectedCountry == 'selected' ||
            selectedTitle == 'selected' ||
            firstName == '' ||
            lastName == '' ||
            emailAddress == '' ||
            dateOfBirth == '' ||
            phoneNo == '') &&
            !displaySuccess
        "
        data-toggle="modal"
        data-target="#liveAccountModal"
        @click="submitForm"
        class="btn btn-primary"
      >
        Submit
      </button>
    </div>
  </div>
</template>
<script>
import countries from "./countries.json";
// import LoadScript from 'vue-plugin-load-script';
export default {
  name: "trust-fx-capital-live-account",
  data() {
    return {
      countries: countries,
      selectedCountry: "selected",
      selectedTitle: "selected",
      firstName: "",
      lastName: "",
      emailAddress: "",
      alternateEmailAddress: "",
      dateOfBirth: "",
      phoneNo: "",
      displaySuccess: false,
    };
  },
  beforeCreate() {
    let externalScript = document.createElement("script");
    externalScript.setAttribute("src", "https://smtpjs.com/v3/smtp.js");
    document.head.appendChild(externalScript);

    let externalScript2 = document.createElement("script");
    externalScript2.setAttribute(
      "src",
      "https://cdn.jsdelivr.net/npm/emailjs-com@3/dist/email.min.js"
    );
    document.head.appendChild(externalScript2);
  },
  methods: {
    resetForm() {
      this.selectedCountry = "selected";
      this.selectedTitle = "selected";
      this.firstName = "";
      this.lastName = "";
      this.emailAddress = "";
      this.alternateEmailAddress = "";
      this.dateOfBirth = "";
      this.phoneNo = "";
    },
    submitForm() {
      // const body = `Country: ${this.selectedCountry}<br />
      //                 Title: ${this.selectedTitle}<br />
      //                 First Name: ${this.firstName}<br />
      //                 Last Name: ${this.lastName}<br />
      //                 Email Address: ${this.emailAddress}<br />
      //                 Alternate Email Address: ${this.alternateEmailAddress}<br />
      //                 Date of Birth: ${this.dateOfBirth}<br />
      //                 Phone Number: ${this.phoneNo}<br />`;
      // if(window.Email) {
      //     window.Email.send({
      //         Host: "smtp.gmail.com",
      //         Username : "TrustFxCapital2@gmail.com",
      //         Password : "TrustFxCapital@02",
      //         To : 'support@trustfxcapital.in',
      //         From : "TrustFxCapital2@gmail.com",
      //         Subject : "Open new account",
      //         Body : body,
      //         });
      window.emailjs.init("vDcgX3sQrGksTDCdw");
      let templateParams = {
        selectedCountry: this.selectedCountry,
        selectedTitle: this.selectedTitle,
        firstName: this.firstName,
        lastName: this.lastName,
        emailAddress: this.emailAddress,
        alternateEmailAddress: this.alternateEmailAddress,
        dateOfBirth: this.dateOfBirth,
        phoneNo: this.phoneNo,
      };

      window.emailjs
        .send("service_6arlaxx", "template_plvralu", templateParams)
        .then(
          (response) => {
            console.log("SUCCESS!", response.status, response.text);
          },
          (error) => {
            console.log("FAILED...", error);
          }
        );
      this.displaySuccess = true;
      this.resetForm();
    },
  },
};
</script>
<style scoped>
.main-container {
  color: black;
}
.form__title {
  border: 1px solid #ccc;
  padding: 1.2rem 2rem;
  background: linear-gradient(to right bottom, #536976, #222021);
  color: #fff;
  border-radius: 0.3rem;
  margin-bottom: 1rem;
}
.col-custom {
  float: left;
  padding: 2% 2% 1% 0.4%;
}
.action {
  display: inline-block;
  margin: 1em 0;
}
.button {
  margin-right: 2em;
}
.close {
  float: right;
}
</style>
