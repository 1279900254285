<template>
  <div class="main-container">
    <div
      class="modal fade"
      id="withdrawalModal"
      tabindex="-1"
      data-keyboard="false"
      data-backdrop="static"
      role="dialog"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Success</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              @click="displaySuccess = false"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              Thank you for submitting the details. We will process the
              transaction shortly.
            </p>
          </div>
        </div>
      </div>
    </div>
    <form>
      <div class="form__title">Withdrawal</div>
      <div class="form-group form__register col-custom">
        <input
          type="text"
          class="form-control"
          placeholder="Full Name *"
          v-model="fullName"
        />
      </div>
      <div class="form-group form__register col-custom">
        <input
          type="text"
          class="form-control"
          placeholder="Trading Account Number *"
          v-model="tradingAccountNumber"
        />
      </div>
      <div class="form-group form__register col-custom">
        <input
          type="text"
          class="form-control"
          placeholder="Bank Account Number *"
          v-model="bankAccountNumber"
        />
      </div>
      <div class="form-group form__register col-custom">
        <input
          type="text"
          class="form-control"
          placeholder="Amount *"
          v-model="amount"
        />
      </div>
    </form>
    <div class="action">
      <button
        :disabled="
          (fullName == '' ||
            tradingAccountNumber == '' ||
            bankAccountNumber == '' ||
            amount == '') &&
            !displaySuccess
        "
        data-toggle="modal"
        data-target="#withdrawalModal"
        @click="submitForm"
        class="btn btn-primary"
      >
        Submit
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "trust-fx-capital-withdrawals",
  data() {
    return {
      fullName: "",
      tradingAccountNumber: "",
      bankAccountNumber: "",
      amount: "",
      displaySuccess: false,
    };
  },
  beforeCreate() {
    let externalScript = document.createElement("script");
    externalScript.setAttribute("src", "https://smtpjs.com/v3/smtp.js");
    document.head.appendChild(externalScript);

    let externalScript2 = document.createElement("script");
    externalScript2.setAttribute(
      "src",
      "https://cdn.jsdelivr.net/npm/emailjs-com@3/dist/email.min.js"
    );
    document.head.appendChild(externalScript2);
  },
  methods: {
    submitForm() {
      window.emailjs.init("vDcgX3sQrGksTDCdw");
      // const body = `Full Name: ${this.fullName}<br />
      //               Trading Account Number: ${this.tradingAccountNumber}<br />
      //               Bank Account Number: ${this.bankAccountNumber}<br />
      //               Amount: ${this.amount}<br />`;
      // if(window.Email !== undefined) {
      //     window.Email.send({
      //         Host: "smtp.gmail.com",
      //         Username : "TrustFxCapital@yahoo.com",
      //         Password : "Swapnil@01",
      //         To : 'support@trustfxcapital.in',
      //         From : "TrustFxCapital@yahoo.com",
      //         Subject : "Withdraw amount",
      //         Body : body,
      //         });
      //       this.displaySuccess = true;
      //       this.resetForm();
      //     }

      var templateParams = {
        fullName: this.fullName,
        tradingAccountNumber: this.tradingAccountNumber,
        bankAccountNumber: this.bankAccountNumber,
        amount: this.amount,
      };

      window.emailjs
        .send("service_6arlaxx", "template_isztx3o", templateParams)
        .then(
          (response) => {
            this.displaySuccess = true;
            this.resetForm();
            console.log("SUCCESS!", response.status, response.text);
          },
          (error) => {
            this.displaySuccess = true;
            this.resetForm();
            console.log("FAILED...", error);
          }
        );
    },
    resetForm() {
      this.fullName = "";
      this.tradingAccountNumber = "";
      this.bankAccountNumber = "";
      this.amount = "";
    },
  },
};
</script>
<style scoped>
.main-container {
  color: black;
}
.form__title {
  border: 1px solid #ccc;
  padding: 1.2rem 2rem;
  background: linear-gradient(to right bottom, #536976, #222021);
  color: #fff;
  border-radius: 0.3rem;
  margin: 1rem 0;
}
.form__register__input,
.form__register select {
  padding: 0.4rem;
  width: 100%;
  border: 1.1px solid #bbb;
  border-radius: 0.2rem;
  color: #777;
}
.col-custom {
  width: 50%;
  display: block;
  float: left;
  padding: 2% 2% 1% 0.4%;
}
.action {
  display: inline-block;
  margin: 1em 0;
}
.button {
  margin-right: 2em;
}
.close {
  float: right;
}
</style>
