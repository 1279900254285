<template>
  <nav class="trust-fx-capital-navigation navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="/trustfxcapital">Home</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="aboutLink" role="button" data-toggle="dropdown" aria-expanded="false">
            ABOUT
          </a>
          <ul class="dropdown-menu" aria-labelledby="aboutLink">
            <li><router-link class="dropdown-item" :to="'/trustfxcapital/profile'">Profile</router-link></li>
            <li><router-link class="dropdown-item" :to="'/trustfxcapital/whyUs'">Why Us?</router-link></li>
            <li><router-link class="dropdown-item" :to="'/trustfxcapital/careers'">Carreers</router-link></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="productsLink" role="button" data-toggle="dropdown" aria-expanded="false">
            PRODUCTS
          </a>
          <ul class="dropdown-menu" aria-labelledby="productsLink">
            <li><router-link class="dropdown-item" :to="'/trustfxcapital/forex'">Forex</router-link></li>
            <li><router-link class="dropdown-item" :to="'/trustfxcapital/spotMetals'">Spot Metals</router-link></li>
            <li><router-link class="dropdown-item" :to="'/trustfxcapital/commodities'">Commodities</router-link></li>
            <li><router-link class="dropdown-item" :to="'/trustfxcapital/indices'">Indices</router-link></li>
            <li><router-link class="dropdown-item" :to="'/trustfxcapital/shares'">Shares</router-link></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <router-link class="nav-link" to="/trustfxcapital/accountTypes" id="accountTypesLink" role="button">
            ACCOUNT TYPES
          </router-link>
          <!-- <ul class="dropdown-menu" aria-labelledby="accountTypesLink">
            <li><router-link class="dropdown-item" :to="'/trustfxcapital/basic'">Basic Account</router-link></li>
            <li><router-link class="dropdown-item" :to="'/trustfxcapital/premium'">Premium Account</router-link></li>
            <li><router-link class="dropdown-item" :to="'/trustfxcapital/vip'">VIP Account</router-link></li>
          </ul> -->
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="learnForexLink" role="button" data-toggle="dropdown" aria-expanded="false">
            LEARN FOREX
          </a>
          <ul class="dropdown-menu" aria-labelledby="learnForexLink">
            <li><router-link class="dropdown-item" :to="'/trustfxcapital/whatIsForex'">What is Forex</router-link></li>
            <li><router-link class="dropdown-item" :to="'/trustfxcapital/howToStart'">How to Start Trading</router-link></li>
            <li><router-link class="dropdown-item" :to="'/trustfxcapital/technicalAnalysis'">Technical Analysis</router-link></li>
            <li><router-link class="dropdown-item" :to="'/trustfxcapital/fundamental'">Fundamental Analysis</router-link></li>
            <li><router-link class="dropdown-item" :to="'/trustfxcapital/marketHours'">Market Hours</router-link></li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>
</template>

<script>
export default {
  name: 'trust-fx-capital-navigation'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .trust-fx-capital-navigation {
    ::v-deep .navbar-nav {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    ::v-deep .navbar-collapse {
      font-weight: bold;
    }

    ::v-deep .nav-item {
      margin: 0 3rem;
    }
  }
</style>
