<template>
  <div class="trust-fx-capital-accounts-and-products">
    <div class="row">
      <div class="col-sm-6 trust-fx-capital-accounts-and-products__trading-accounts">
        <h2 class="trust-fx-capital-accounts-and-products__title">Trading Accounts</h2>
        <div class="row trust-fx-capital-accounts-and-products__account">
          <div class="col-sm-6">
            <router-link to="/trustfxcapital/accountTypes">
              <img
                src="../../img/trading_account_img_1.png"
                alt="Micro Account"
              />
              <p>Micro Account</p>
            </router-link>
          </div>
          <div class="col-sm-6">
            <router-link to="/trustfxcapital/accountTypes">
              <img
                src="../../img/trading_account_img_2.png"
                alt="Micro Account"
              />
              <p>Premium Account</p>
            </router-link>
          </div>
          <div class="col-sm-6">
            <router-link to="/trustfxcapital/accountTypes">
              <img
                src="../../img/trading_account_img_3.png"
                alt="Micro Account"
              />
              <p>Gold Account</p>
            </router-link>
          </div>
          <div class="col-sm-6">
            <router-link to="/trustfxcapital/accountTypes">
              <img
                src="../../img/trading_account_img_3.png"
                alt="Micro Account"
              />
              <p>Platinum Account</p>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-sm-6 trust-fx-capital-accounts-and-products__trading-accounts">
        <h2 class="trust-fx-capital-accounts-and-products__title">Trading Products</h2>
        <div class="row trust-fx-capital-accounts-and-products__account">
          <div class="col-sm-4">
            <router-link to="/trustfxcapital/forex">
              <img
                src="../../img/trading_product_img_1.png"
                alt="Micro Account"
              />
              <p>Forex</p>
            </router-link>
          </div>
          <div class="col-sm-4">
            <router-link to="/trustfxcapital/spotMetals">
              <img
                src="../../img/trading_product_img_2.png"
                alt="Micro Account"
              />
              <p>Spot Metals</p>
            </router-link>
          </div>
          <div class="col-sm-4">
            <router-link to="/trustfxcapital/commodities">
              <img
                src="../../img/trading_product_img_3.png"
                alt="Micro Account"
              />
              <p>Commodities</p>
            </router-link>
          </div>
          <div class="col-sm-4">
            <router-link to="/trustfxcapital/indices">
              <img
                src="../../img/trading_product_img_4.png"
                alt="Micro Account"
              />
              <p>Indices</p>
            </router-link>
          </div>
          <div class="col-sm-4">
            <a>
              <img
                src="../../img/trading_product_img_5.png"
                alt="Micro Account"
              />
              <p>Energy</p>
            </a>
          </div>
          <div class="col-sm-4">
            <router-link to="/trustfxcapital/shares">
              <img
                src="../../img/trading_product_img_6.png"
                alt="Micro Account"
              />
              <p>Shares</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3"></div>
      <div class="col-sm-6 trust-fx-capital-accounts-and-products__trading-accounts">
        <h2 class="trust-fx-capital-accounts-and-products__title">
          Multiple Platforms
        </h2>
        <div class="row trust-fx-capital-accounts-and-products__account">
          <div class="col-sm-4">
            <a>
              <img
                src="../../img/multiple_platform_img_1.png"
                alt="Micro Account"
              />
              <p>Windows</p>
            </a>
          </div>
          <div class="col-sm-4">
            <a>
              <img
                src="../../img/multiple_platform_img_2.png"
                alt="Micro Account"
              />
              <p>Mac OS X</p>
            </a>
          </div>
          <div class="col-sm-4">
            <a>
              <img
                src="../../img/trading_account_img_3.png"
                alt="Micro Account"
              />
              <p>Multiple Terminal</p>
            </a>
          </div>
          <div class="col-sm-4">
            <a>
              <img
                src="../../img/multiple_platform_img_4.png"
                alt="Micro Account"
              />
              <p>Android</p>
            </a>
          </div>
          <div class="col-sm-4">
            <a>
              <img
                src="../../img/multiple_platform_img_5.png"
                alt="Micro Account"
              />
              <p>IOS</p>
            </a>
          </div>
          <div class="col-sm-4">
            <a>
              <img
                src="../../img/multiple_platform_img_6.png"
                alt="Micro Account"
              />
              <p>Linux</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "trust-fx-capital-account-and-products",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.trust-fx-capital-accounts-and-products {
  text-align: center;
  margin: 0 100px;
  &__trading-accounts {
    margin: 0.5rem;
    width: 45%;
    max-width: 45% !important;
    padding: 0.5rem;
    background: linear-gradient(to right bottom, blue, white);
    color: white;
  }
  &__title {
    margin: 1rem;
    text-align: center;
  }

  &__account {
    > div > a {
      text-decoration: none;
      color: black;
    }
  }
  @media screen and (max-width: 500px) {
      &__trading-accounts {
          width: 100% !important;
          max-width: 100% !important;
      }
      &__account {
          .col-sm-6, .col-sm-4 {
            width: 50%;
          }
      }
  }
}
</style>
