<template>
  <div class="main-container">
    <div>
      <h2 class="main_content_heading">VIP Trading Account</h2>
      <img
        src="../../img/account_types_img_3.jpg"
        class="main_content_img_common product_image"
      />

      <div class="content_base_template">
        <h3>
          You can open an individual trading account, or joint, or company
          account of this type with the below features
        </h3>
        <br />
        <ul>
          <li>The initial deposit amount to open the account is $50,000</li>
          <li>
            Flexible leverage up to 1:100
          </li>
          <li>The minimum trade volume is 1 Standard lot (100,000 units</li>
          <li>
            RAW Spreads (as low as 0.0) on all traded instruments*
          </li>
          <li>24/7 hours highly experienced client support</li>

          <li>Multi-Products &amp; easy-to-use platforms</li>
          <li>
            Hedging, Trailing Stops, Expert Advisor*, &amp; Pending Orders are
            all allowed
          </li>
          <li>
            No Swaps, No Interests, &amp; No Rollovers**
          </li>
          <li>
            Dedicated services &amp; secure access through the client login
            area.
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "trust-fx-capital-vip",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
