<template>
    <div class="main-container">
        <div>
          <h2 class="main_content_heading">Forex</h2>
          <img src="../../img/forex.jpg" class="product_image">

          <div class="content_base_template">
            <p>
              FX or Forex describes the Foreign Exchange Market, a marketplace
              where the world’s various currencies are traded. Its huge volume
              and fluidity made the Forex market the largest and most
              significant financial market in the world, with well over $4
              trillion traded daily which is almost 10 times larger than the
              stock market. Due to the fact that forex currency trading has no
              centralised marketplace, currencies can be traded in whatever
              market is open at any given time, creating a great opportunity for
              traders to buy and sell currencies around the clock 24 hours a
              day, 5 days a week with the exception of weekends.
              <br>
              <br>
              TrustFxCapital offers its clients one the most advanced forex trading
              platform enabling you to trade currency pairs ranging from majors
              to exotic pairs. Our team of specialists is fully trained to offer
              any trading platform assistance required and assist with setting
              up an account for you.
              <br>
              <br>
              Discover the currency markets for yourself today by simply opening
              up an account with us, or contact us for further information.
            </p>
          </div>

          <div class="content_base_template">
            <h2 class="template_product_heading">Forex Trading Examples</h2>
            <h3>Selling EUR/USD</h3>
            <br>
            <p>Leverage 1:300</p>
            <br>
            <h3>Opening the PositionD</h3>
            <br>
            <p>
              Opening price of the EURO against the US Dollar (EUR/USD) is
              1.25412
            </p>
            <br>
            <p>
              You decide to sell 1 standard lot (the equivalent of $100,000) at
              1.25412
            </p>
            <br>
            <p>
              Margin required to open the position is USD $125,412/300 = USD
              $418.04
            </p>
            <br>
            <h3>Closing the Position</h3>
            <p>
              One week later the EURO has fallen against the US Dollar to
              1.24323, you decide to take your profit by closing your selling
              position.
            </p>
            <p>Market movement = 1.25412 – 1.24323 = 1089 points =108.9 pips</p>
            <p>1 pip of EUR/USD (per 1 lot) = $10</p>
            <p>Gross profit on Trade = USD $10*108.9 = USD $ 1,089</p>
          </div>
        </div>
      </div>
</template>
<script>
export default {
  name: "trust-fx-capital-forex",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
