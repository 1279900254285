<template>
  <div class="main-container">
    <div>
      <h2 class="main_content_heading">How to start Trading ?</h2>

      <div class="content_base_template">
        <p>
          Whether a currency is increasing or declining in value, there is
          always a way for you to make money in Forex!
        </p>
      </div>

      <hr />

      <div class="content_base_template">
        <h2>Entering the Market</h2>
        <p>
          The first step for becoming an FX trader is to open an account with a
          forex Broker. Brokers in these markets usually give the investors the
          opportunity to test their trading system and trading facilities
          through opening a demo account on their trading platform and start
          practicing buying and selling currencies.

          <br />
          <br />
          The most important aspect of FX trading is to understand the FX
          platforms, as they are the direct connection to the financial and
          forex markets. They are the software you use to place your orders,
          watch the market prices and also place transactions.
        </p>
        <br />
        <br />
        <p>
          As a trader, you would be sitting at the computer screen, looking for
          signals and interpreting whether to buy or sell. The main concept is
          to buy a product hoping to sell it on a higher price or vice versa, so
          that the difference is your profits, i.e. you need to buy low and sell
          high.
        </p>
      </div>
      <hr />

      <div class="content_base_template">
        <h3>Tools to get started</h3>
        <br />
        <ul>
          <li>Computer or Smartphone</li>
          <li>Reliable internet connection</li>
          <li>
            Trading software (platform) usually made available by the broker
          </li>
          <li>Trading capital</li>
          <li>Forex Broker</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "trust-fx-capital-how-to-start",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
